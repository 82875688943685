import React, { FC, useCallback, useState } from 'react';
import { Form } from '../../../../Components/Form';
import { useIntl } from 'react-intl';
import { AptorApi } from '../../../../Api/AptorApi';
import { useAptorApi } from '../../../../Api';
import { entityToSelectOption } from '../../../../Utilities';
import { useFormField } from '../../../../Components/Form/FormField';
import { FormValues, SelectOption } from '../../../../Components/Form/Form.types';
import { EditablePanel, HtmlForm, HtmlView } from '../../../../Components';
import { ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, Grid, IconButton, Tooltip } from '@material-ui/core';
import { Title } from '../../../../Components/Panel/Panel';
import { Block, Delete, Edit, ExpandMore, SyncSharp } from '@material-ui/icons';

interface IProps {
  submit: (
    form: FormValues,
    api: AptorApi,
    onSuccess: (successMessage?: string | undefined | null) => void,
  ) => Promise<void>;
  areas: SelectOption[];
  categories: SelectOption[];
  tags: SelectOption[];
  countries: SelectOption[];
}
interface ApplicationData {
  heading: string;
  application: string;
}
interface IconProps {
  id: number;
  name: string;
  icon?: React.ReactNode;
  onClick?: (event: any, lawData?: any) => void;
}
export const CreateLawForm: FC<IProps> = ({ submit, areas, categories, tags, countries }) => {
  const { formatMessage } = useIntl();
  const { api, abortController } = useAptorApi();
  const [applicationEditCaseShow, setApplicationEditCaseShow] = useState<any>("")
  const [panelOpen, setPanelOpen] = useState<any>("")
  const [revision, setRevision] = useState<number>(0);
  const [applications, setApplications] = useState<any[]>([])
  const onSubmit = (form: FormValues, api: AptorApi, onSuccess: (successMessage?: string | undefined | null) => void) =>
    submit({...form,applications:applications}, api, onSuccess);


  const name = useFormField({
    label: formatMessage({ id: 'admin.law-portal.law.form.name.label', defaultMessage: 'Name' }),
    name: 'name',
    type: 'text',
    required: true,
  });

  const number = useFormField({
    label: formatMessage({ id: 'admin.law-portal.law.form.number.label', defaultMessage: 'Number' }),
    name: 'number',
    type: 'text',
    required: true,
  });

  const category = useFormField({
    label: formatMessage({ id: 'admin.law-portal.law.form.category.label', defaultMessage: 'Category' }),
    name: 'category',
    type: 'single-select',
    options: categories,
    required: true,
  });

  const area = useFormField({
    label: formatMessage({ id: 'admin.law-portal.law.form.area.label', defaultMessage: 'Area' }),
    name: 'area',
    type: 'single-select',
    options: areas,
    required: true,
  });

  const tag = useFormField({
    label: formatMessage({ id: 'admin.law-portal.law.form.tags.label', defaultMessage: 'Tags' }),
    name: 'tags',
    type: 'multi-select',
    options: tags,
    required: false,
  });

  const effectiveFrom = useFormField({
    label: formatMessage({ id: 'admin.law-portal.law.form.effectiveFrom.label', defaultMessage: 'Effective from' }),
    name: 'effectiveFrom',
    type: 'date',
    required: true,
  });

  const url = useFormField({
    label: formatMessage({ id: 'admin.law-portal.law.form.url.label', defaultMessage: 'Url' }),
    name: 'url',
    type: 'text',
    required: true,
  });

  const urlName = useFormField({
    label: formatMessage({ id: 'admin.law-portal.law.form.urlName.label', defaultMessage: 'Link name' }),
    name: 'urlName',
    type: 'text',
    placeholder: formatMessage({
      id: 'admin.law-portal.law.form.urlName.placeholder',
      defaultMessage: 'Read the law in its entirety',
    }),
    required: false,
  });

  const country = useFormField({
    label: formatMessage({ id: 'admin.law-portal.law.form.country.label', defaultMessage: 'Country' }),
    name: 'country',
    type: 'single-select',
    options: countries,
    required: true,
  });

  const loadOptions = useCallback(
    async (search) => {
      const result = await api.searchAvailableLaws({ search });
      if (abortController.current.signal.aborted) {
        return undefined;
      }
      return result.items.map(entityToSelectOption);
    },
    [api, abortController],
  );

  const replacesLaw = useFormField({
    label: formatMessage({ id: 'admin.law-portal.law.form.replacesLaw.label', defaultMessage: 'Replaces law' }),
    name: 'replacedLawId',
    type: 'single-select-async',
    loadOptions: loadOptions,
    required: false,
  });

  const description = useFormField({
    label: formatMessage({ id: 'admin.law-portal.law.form.description.label', defaultMessage: 'Short description' }),
    name: 'description',
    type: 'rich-text',
    required: false,
  });

  const chapter = useFormField({
    label: formatMessage({ id: 'admin.law-portal.law.form.chapter.label', defaultMessage: 'Chapter/Paragraph' }),
    name: 'chapter',
    type: 'rich-text',
    required: false,
    richTextEditorHeight: 'small',
  });

  const application = useFormField({
    label: formatMessage({ id: 'admin.law-portal.law.form.application.label', defaultMessage: 'Application' }),
    name: 'application',
    type: 'rich-text',
    required: false,
  });

  const submitApplication = async (form: any, aptorApi: AptorApi, onSuccess: () => void) => {
    setApplications([...applications, form])
  };
  const editApplication = async (form: any, aptorApi: AptorApi, onSuccess: () => void) => {
    let applicationData = [...applications]
    applicationData[applicationEditCaseShow] = form
    setApplications(applicationData)
    setPanelOpen(applicationEditCaseShow)
    setApplicationEditCaseShow('')
  }
  const data: IconProps[] = [
    {
      id: 1,
      name: 'law-portal.legal.plan.edit',
      icon: <Edit />,
      onClick: (event: any, lawData?: any) => {
        event.stopPropagation()
        setPanelOpen("")
        setApplicationEditCaseShow(lawData?.id)
      }
    },
    {
      id: 2,
      name: 'law-portal.legal.plan.delete',
      icon: <Delete />,
      onClick: (event: any, lawData?: any) => {
        event.stopPropagation()
       let applicationData=applications.filter((item,index)=>index!==lawData.id)
       setApplications(applicationData)
      }
    },
  ];
  const IconsNode = ({ lawData }: any) => {
    const actionIcon = data
    return (
      <Grid>
        {actionIcon?.map((item,index)=>{
          return  <Tooltip title={formatMessage({ id: item.name })} key={index}>
          <IconButton
            style={{ cursor: 'pointer', pointerEvents: 'auto' }}
            onClick={(event) => item.onClick && item.onClick(event, lawData)}
            key={item.id}
          >
            {item.icon}
          </IconButton>
        </Tooltip>
        })}
      </Grid>
    );
  };
  return (
    <Form
      submit={onSubmit}
      groups={[
        {
          grid: {
            type: 'column',
            items: [
              {
                type: 'row',
                items: [
                  { type: 'column', items: [name, effectiveFrom, category, url, country] },
                  { type: 'column', items: [number, area, tag, urlName, replacesLaw] },
                  { type: 'column', flex: 2, items: [description] },
                ],
              },
              chapter,
              // application,
            ],
          },
        },
      ]}
      form={
        <Grid item xs={12}>
          <EditablePanel
            titleKey="admin.law-portal.law.form.application.label"
            dependencies={[revision]}
            iconType="add"
            view={
              <>
                {/* <LawLink variant="caption" type="law-application" url={law.link.url} urlText={law.link.text} />
                  <HtmlView value={law.application} /> */}
                <Grid style={{ display: 'flex', flexDirection: 'column', gap: '15px' }} item>
                  {applications?.map((val, index) => {
                    console.log((applicationEditCaseShow == index && applicationEditCaseShow != "") || (panelOpen == index && panelOpen != ""), "dkndkdn")
                    return (
                      <ExpansionPanel
                        defaultExpanded={false}
                        expanded={(applicationEditCaseShow === index && applicationEditCaseShow !== "") || (panelOpen === index && panelOpen !== "")}
                        onChange={() => {
                          const newState = applicationEditCaseShow === index || panelOpen === index ? "" : index;
                          setApplicationEditCaseShow("");
                          setPanelOpen(newState);
                        }}
                      >
                        <ExpansionPanelSummary style={{ pointerEvents: "none" }} expandIcon={<ExpandMore style={{ pointerEvents: 'auto' }} />}>
                          <Grid container justifyContent='space-between' alignItems='center'>
                            <Title title={`${index+1} ${val?.heading}`} />
                            <Grid >
                              <IconsNode lawData={{ ...val, id: index }} />
                            </Grid>
                          </Grid>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails  >
                          {applicationEditCaseShow === index && applicationEditCaseShow !== "" ? (
                            <HtmlForm
                              propertyNameTranslationKey="admin.law-portal.law.form.application.label"
                              value={val.application}
                              headingValue={val?.heading}
                              submit={editApplication}
                              heading={true}
                            />
                          ) : (
                            <HtmlView value={val.application} />
                          )}
                        </ExpansionPanelDetails>
                      </ExpansionPanel>
                    )
                  })}
                </Grid>
              </>
            }
            form={
              <HtmlForm
                propertyNameTranslationKey="admin.law-portal.law.form.application.label"
                value={""}
                submit={submitApplication}
                heading={true}
                headingValue={""}
              />
            }
          />
        </Grid>
      }
    />
  );
};
