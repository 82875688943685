import React, { FC, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Typography,
} from '@material-ui/core';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSnackbar } from 'notistack';
import { useStyles } from './ConfirmationContext.styles';
import { ConfirmationMessage } from './ConfirmationMessage';

interface IProps {
  name?: string;
  title?: string;
  description?: React.ReactNode;
  disabled?: boolean;
  hideSavedNotification?: boolean;
  hideCancel?: boolean;
}

type Confirmation = {
  deleteConfirmation: (onAccept: () => Promise<void>, props?: IProps, onCancel?: () => void) => void;
};

export const ConfirmationContext = React.createContext<Confirmation>({
  deleteConfirmation: (_, __, ___) => {},
});

export const useConfirmation = () => React.useContext(ConfirmationContext);

interface IState extends IProps {
  open: boolean;
  onAccept: () => Promise<void>;
  onCancel?: () => void;

  loading: boolean;
}

export const ConfirmationProvider: FC = ({ children }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { formatMessage } = useIntl();
  const initialState = { open: false, onAccept: () => Promise.resolve(), name: undefined, loading: false };
  const [state, setState] = useState<IState>(initialState);
  const close = () => setState({ ...state, open: false });
  const onDeleteConfirmation = (onAccept: () => Promise<void>, props?: IProps, onCancel?: () => void) => {
    setState({
      ...state,
      ...{
        onAccept,
        onCancel,
        name: props?.name,
        hideSavedNotification: props?.hideSavedNotification,
        hideCancel: props?.hideCancel,
        open: true,
        title: props?.title,
        description: props?.description,
        disabled: props?.disabled,
      },
    });
  };

  const onCancel = () => {
    close();
    if (state.onCancel) {
      state.onCancel();
    }
  };

  const onAccept = async () => {
    setState({ ...state, ...{ loading: true } });
    try {
      await state?.onAccept();
    } catch (error) {
      enqueueSnackbar(formatMessage({ id: 'utils.snackbar.somethingWentWrong' }), { variant: 'error' });
      close();
    }

    !state.hideSavedNotification &&
      enqueueSnackbar(formatMessage({ id: 'utils.snackbar.saved' }), { variant: 'success' });
    close();
  
  };

  const classes = useStyles();

  return (
    <ConfirmationContext.Provider value={{ deleteConfirmation: onDeleteConfirmation }}>
      {children}
      <Dialog open={state.open} onClose={onCancel} fullWidth maxWidth="sm">
        <DialogTitle className={classes.title} id="alert-dialog-title">
          {(state.title && <Typography>{state.title}</Typography>) || (
            <FormattedMessage id="utils.delete-confirmation.title" />
          )}
        </DialogTitle>
        <DialogContent className={classes.content}>
          <DialogContentText id="alert-dialog-description">
            <ConfirmationMessage {...state} />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {!state.hideCancel && (
            <Button onClick={onCancel} disabled={state.loading} color="secondary">
              <FormattedMessage id="utils.delete-confirmation.cancel" />
            </Button>
          )}

          <Button onClick={onAccept} disabled={state.loading || state.disabled === true} color="primary" autoFocus>
            <FormattedMessage id="utils.delete-confirmation.ok" />
          </Button>
        </DialogActions>
      </Dialog>
    </ConfirmationContext.Provider>
  );
};
