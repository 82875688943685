import React, { useState, useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { Grid, Button, Tooltip, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, IconButton } from '@material-ui/core';
import { AssignmentTurnedIn, Block, Delete, Edit, ExpandMore, SkipNext, SyncSharp } from '@material-ui/icons';
import { Loading, EditablePanel, HtmlView, HtmlForm, LawLink, Container, BackButton } from '../../../../../Components';
import { ButtonLink } from '../../../../../Components/ButtonLink/ButtonLink';
import { EditLawInformationForm } from './Information.Form';
import { EditLawInformationView } from './Information.View';
import { ReplaceLawView, ReplaceLawForm } from './ReplaceLaw';
import { EditLawRelatedLaws } from './RelatedLaws';
import { EditLawChangesView } from './Changes.View';
import { EditLawAddChange } from './Changes.Form';
import { useOptions } from '../Options';
import { AptorApi, useAptorApi } from '../../../../../Api';
import { INamedEntity } from '../../../../../Api/AptorApi';
import { IChange } from '../../../../../Components/LawChange';
import { useStyles } from "../../../AptorAdminModule.styles";
import { ArrowUpward } from '@material-ui/icons'
import { Title } from '../../../../../Components/Panel/Panel';
export interface ILawChange extends IChange {
  isPosibleToDelete: boolean;
}
interface IconProps {
  id: number;
  name: string;
  icon?: React.ReactNode;
  onClick?: (event: any, lawData?: any) => void;
}
interface ILaw {
  name: string;
  number: string;
  shortDescription: string;
  chapter: string;
  application: string;
  applications: any[];
  effectiveFrom: Date;
  effectiveTo?: Date;
  area: INamedEntity;
  category: INamedEntity;
  tags: INamedEntity[];
  country: INamedEntity;
  replacesLaw?: INamedEntity;
  replacesLaws?:any[];
  link: { url: string; text?: string };
  changes: ILawChange[];
  checklistId?: number;
}
interface ApplicationData {
  id: number;
  heading: string;
  application: string;
}
export const EditLaw = () => {
  const match = useRouteMatch<{ id: string }>();
  const id = parseInt(match.params.id);
  const [options] = useOptions();
  const { api, abortController } = useAptorApi();
  const [revision, setRevision] = useState<number>(0);
  const [law, setLaw] = useState<ILaw>();
  const { formatMessage } = useIntl();
  const [applicationEditCaseShow, setApplicationEditCaseShow] = useState<any>("")
  const [panelOpen, setPanelOpen] = useState<any>("")
  const classes = useStyles()
  useEffect(() => {
    getLawData()
  }, [api, abortController, id]);

  const getLawData = () => {
    api.getLaw<ILaw>(id).then((response) => {
      if (abortController.current.signal.aborted) {
        return;
      }
      setLaw(response);
    });
  }
  const updateLawState = (update: Partial<ILaw>) => {
    if (law) {
      setLaw({ ...law, ...update });
      setTimeout(() => setRevision(revision + 1), 250);
    }
  };

  const deleteLawReplacement = async (replacementId:number) => {
    // await api.replaceLawDeprecated(+id, null);
    await api.deleteReplaceLaw(+id,replacementId );
    await getLawData()
    // updateLawState({ replacesLaw: undefined });
  };

  const submitLawReplacement = async (value: INamedEntity, aptorApi: AptorApi) => {
    await aptorApi.replaceLaw(+id, value.id);
    await getLawData()
    setTimeout(() => setRevision(revision + 1), 250);
  };

  const submitShortDescription = async (value: string, aptorApi: AptorApi, onSuccess: () => void) => {
    await aptorApi.updateLawShortDescription(id, value);
    onSuccess();
    updateLawState({ shortDescription: value });
  };

  const submitChapter = async (value: string, aptorApi: AptorApi, onSuccess: () => void) => {
    await aptorApi.updateLawChapter(id, value);
    onSuccess();
    updateLawState({ chapter: value });
  };

  const submitApplication = async (value:any, aptorApi: AptorApi, onSuccess: () => void) => {
    await aptorApi.addAndUpdateLawApplication(id, 0, {...value,status:1});
    setApplicationEditCaseShow('')
    onSuccess();
    setTimeout(() => setRevision(revision + 1), 250);
    getLawData()
  };
  const submitApplications = async (value: any, aptorApi: AptorApi, onSuccess: () => void, applicationId: number) => {
    await aptorApi.addAndUpdateLawApplication(id, applicationId, value);
    setPanelOpen(applicationId)
    setApplicationEditCaseShow('')
    onSuccess();
    getLawData()
  };

  const [showScrollTop, setShowScrollTop] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setShowScrollTop(true);
      } else {
        setShowScrollTop(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  if (options === null || law === undefined) {
    return <Loading />;
  }
  const data: IconProps[] = [
    {
      id: 1,
      name: 'law-portal.legal.plan.edit',
      icon: <Edit />,
      onClick: (event: any, lawData?: any) => {
        event.stopPropagation()
        setPanelOpen("")
        setApplicationEditCaseShow(lawData?.id)
      }
    },
    {
      id: 2,
      name: 'org.law.application.inactive',
      icon: <Block />,
      onClick: async(event: any, lawData?: any) => {
        event.stopPropagation()
        let data = { ...lawData, status: 0 }
        await api.addAndUpdateLawApplication(id, lawData.id, data)
        setPanelOpen("")
        setApplicationEditCaseShow('')
        getLawData()
      }
    },
    {
      id: 3,
      name: 'org.law.application.active',
      icon: <SyncSharp />,
      onClick: async(event: any, lawData: any) => {
        event.stopPropagation();
        let data = { ...lawData, status: 1}
        await api.addAndUpdateLawApplication(id, lawData.id, data)
        setPanelOpen("")
        setApplicationEditCaseShow('')
        getLawData()
      },
    },
    {
      id: 4,
      name: 'law-portal.legal.plan.delete',
      icon: <Delete />,
      onClick: async (event: any, lawData?: any) => {
        event.stopPropagation()
        let data = { ...lawData, IsDeleted: true }
        await api.addAndUpdateLawApplication(id, lawData.id, data)
        setPanelOpen("")
        setApplicationEditCaseShow('')
        getLawData()
      }
    },
  ];
  const IconsNode = ({ lawData }: any) => {
    const actionIcon = data
    return (
      <Grid>
        {actionIcon?.map((item, index) => {
          if (lawData.status == 1) {
            return item.name != "org.law.application.active" && <Tooltip title={formatMessage({ id: item.name })} key={index}>
              <IconButton
                style={{ cursor: 'pointer', pointerEvents: 'auto' }}
                onClick={(event) => item.onClick && item.onClick(event, lawData)}
                key={item.id}
              >
                {item.icon}
              </IconButton>
            </Tooltip>
          }else {
            return item.name != "org.law.application.inactive" && <Tooltip title={formatMessage({ id: item.name })} key={index}>
              <IconButton
                style={{ cursor: 'pointer', pointerEvents: 'auto' }}
                onClick={(event) => item.onClick && item.onClick(event, lawData)}
                key={item.id}
              >
                {item.icon}
              </IconButton>
            </Tooltip>
          }
        })}
        {/* {actionIcon?.map((item, index) => (
          <Tooltip title={formatMessage({ id: item.name })} key={index}>
            <IconButton
              style={{ cursor: 'pointer', pointerEvents: 'auto' }}
              onClick={(event) => item.onClick && item.onClick(event, lawData)}
              key={item.id}
            >
              {item.icon}
            </IconButton>
          </Tooltip>
        ))} */}
      </Grid>
    );
  };
  return (
    <>
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container justify="space-between">
              <Grid item>
                <BackButton disableMargin link="/admin/law-portal/laws" />
              </Grid>
              <Grid item>
                <ButtonLink
                  to={`/admin/law-portal/laws/${id}/checklist`}
                  text={formatMessage({
                    id: 'admin.law-portal.law.form.checklist.edit.button',
                    defaultMessage: 'Edit checklist',
                  })}
                  icon={<AssignmentTurnedIn />}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={8}>
            <EditablePanel
              titleKey="admin.law-portal.law.form.information.label"
              dependencies={[revision]}
              view={<EditLawInformationView {...law} />}
              form={<EditLawInformationForm options={options} id={id} {...law} onChange={updateLawState} />}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <EditablePanel
              titleKey="admin.law-portal.law.form.replacesLaw.label"
              dependencies={[revision]}
               iconType="add"
              view={
                <ReplaceLawView
                replacedLaws={law.replacesLaws}
                  submit={(replacementId:number)=>deleteLawReplacement(replacementId)}
                />
              }
              form={<ReplaceLawForm id={+id} replacedLaws={law.replacesLaws} submit={submitLawReplacement} />}
            />
          </Grid>
          <Grid item xs={12}>
            <EditablePanel
              titleKey="admin.law-portal.law.form.description.label"
              dependencies={[revision]}
              view={
                <>
                  <HtmlView value={law.shortDescription} />
                  <LawLink type="law-description" url={law.link.url} urlText={law.link.text} />
                </>
              }
              form={
                <HtmlForm
                  propertyNameTranslationKey="admin.law-portal.law.form.description.label"
                  value={law.shortDescription}
                  submit={submitShortDescription}
                />
              }
            />
          </Grid>
          <Grid item xs={12}>
            <EditablePanel
              iconType="add"
              titleKey="admin.law-portal.law.form.latest-change.label"
              dependencies={[revision]}
              view={<EditLawChangesView id={id} changes={law.changes} onChange={updateLawState} />}
              form={<EditLawAddChange id={id} changes={law.changes} onChange={updateLawState} />}
            />
          </Grid>
          <Grid item xs={12}>
            <EditablePanel
              titleKey="admin.law-portal.law.form.chapter.label"
              dependencies={[revision]}
              view={<HtmlView value={law.chapter} />}
              form={
                <HtmlForm
                  propertyNameTranslationKey="admin.law-portal.law.form.chapter.label"
                  value={law.chapter}
                  submit={submitChapter}
                />
              }
            />
          </Grid>
          <Grid item xs={12}>
            <EditablePanel
              titleKey="admin.law-portal.law.form.application.label"
              dependencies={[revision]}
              iconType="add"
              view={
                <>
                  {/* <LawLink variant="caption" type="law-application" url={law.link.url} urlText={law.link.text} />
                  <HtmlView value={law.application} /> */}
                  <Grid style={{ display: 'flex', flexDirection: 'column', gap: '15px' }} item>
                    {law?.applications?.map((val, index) => {
                      return (
                        <ExpansionPanel
                          defaultExpanded={false}
                          expanded={applicationEditCaseShow == val?.id || panelOpen == val?.id ? true : false}
                          onChange={() => {
                            setApplicationEditCaseShow("")
                            //  const [applicationEditCaseShow, setApplicationEditCaseShow] = useState<any>("")
                            // const [panelOpen,setPanelOpen]=useState<any>("")
                            setPanelOpen(applicationEditCaseShow == val?.id || panelOpen == val?.id ? "" : val?.id)
                          }}
                        >
                          <ExpansionPanelSummary style={{ pointerEvents: "none" }} expandIcon={<ExpandMore style={{ pointerEvents: 'auto' }} />}>
                            <Grid container justifyContent='space-between' alignItems='center'>
                              <div style={val?.status==0?{color:'gray'}:{}}><Title title={`${index + 1} ${val?.heading}`} /></div>
                              <Grid >
                                <IconsNode lawData={val} />
                              </Grid>
                            </Grid>
                          </ExpansionPanelSummary>
                          <ExpansionPanelDetails  >
                            {applicationEditCaseShow == val?.id ?
                              <HtmlForm
                                propertyNameTranslationKey="admin.law-portal.law.form.application.label"
                                value={val.application}
                                headingValue={val?.heading}
                                submit={(value: any, aptorApi: AptorApi, onSuccess: () => void) => submitApplications({ ...val, ...value }, aptorApi, onSuccess, val?.id)}
                                heading={true}
                              /> :
                              <HtmlView value={val.application} />
                            }
                          </ExpansionPanelDetails>
                        </ExpansionPanel>
                      )
                    })}
                  </Grid>
                </>
              }
              form={
                <HtmlForm
                  propertyNameTranslationKey="admin.law-portal.law.form.application.label"
                  value={""}
                  submit={submitApplication}
                  heading={true}
                />
              }
            />
          </Grid>
          <Grid item xs={12}>
            <EditablePanel
              titleKey="admin.law-portal.law.form.related-laws.label"
              dependencies={[revision]}
              view={<EditLawRelatedLaws id={id} key={id} />}
              form={<EditLawRelatedLaws id={id} formOn />}
            />
          </Grid>
        </Grid>
      </Container>
      {showScrollTop && (
        <Tooltip title={formatMessage({ id: "bottom.to.top.btn.tooltip.text", defaultMessage: "Back to top" })}>
          <Button
            onClick={() => {
              window.scrollTo({ top: 0, behavior: 'smooth' });
            }}
            className={classes.topButton}
          >
            <ArrowUpward style={{ fontSize: 20 }} />
          </Button>
        </Tooltip>
      )}
    </>
  );
};
