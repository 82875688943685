import { AuthenticatedApi } from './Core/AuthenticatedApi';
import { getAppConfiguration } from '../Config';
import { FormValues, SelectOption } from '../Components/Form/Form.types';
import { UserStatus } from '../Context/UserContext/UserContext';
import { EntityList } from '../Utilities';

export interface INamedEntity {
  id: number;
  name: string;
}

interface IEnumeration {
  value: number;
  displayName: string;
  name: string;
}

type LanguageCode = 'sv' | 'en';

interface ILanguage extends INamedEntity, IEnumeration {
  cultureKey: LanguageCode;
}

interface IMailSettings extends INamedEntity, IEnumeration {}
interface ICountry extends INamedEntity {}
interface IPermission extends INamedEntity {}

interface IApiRole {
  value: number;
  displayName: string;
  features: string[];
}

interface IApiUser {
  id: number;
  email: string;
  firstName: string;
  tableRowCount:number;
  lastName: string;
  name:string,
  department: string | null;
  position: string | null;
  phoneNumber: string | null;
  role: IApiRole;
  language: ILanguage;
  mailSettings: IMailSettings;
  featureAccess: { feature: IEnumeration & { module: IEnumeration }; accessLevel: IEnumeration }[];
  customerName?: string;
  customerId?: number;
  companyUnits: Array<{id: number, name: string}>;
  customerCompanyUnitAlias?: string;
  complianceUserFor: {
    laws: Array<{ id: number; customerCompanyUnitId: number }>;
    requirements: Array<{ id: number; customerCompanyUnitId: number }>;
  };
}

interface IImpersonationUsers {
  users: SelectOption[];
}

export interface IApiCustomerInfo {
  name: string;
  organizationNr: string;
  contactPerson: string;
  address: string;
  city: string;
  postalCode: string;
  numberOfAllowedCompanyUnits: number;
  numberOfAllowedUsers: number;
  billingAddress: string;
  billingEmail: string;
  billingCity: string;
  billingPostalCode: string;
  referencePerson: string;
  phoneNumber: string;
  organizationUsers: Array<{ id: number; name?: string; email: string; status: UserStatus }>;
  selectedFeatures: Array<{ id: number; name: string }>;
  selectedCountries: Array<{ id: number; name: string }>;
  configurableModuleFeatures: Array<{ id: number; name: string }>;
}

export interface IGridQueryResult<T> {
  data: T[];
  page: number;
  totalCount: number;
}

export enum OrderDirection {
  Ascending,
  Descending,
}

export interface IGridQueryRequest {
  page: number;
  pageSize: number;
  orderBy: string;
  orderDirection: OrderDirection;
  search?: string;
  filters?: [string];
}

export type PropertyErrorMessageType = 'notification' | 'property';

export type PropertyError = {
  errors: Array<{ message: string; type: PropertyErrorMessageType }>;
  name: string;
};

export type ErrorResponse = {
  errors: PropertyError[];
  type: 'validation';
};

export interface IListResult<ItemType> {
  items: ItemType[];
}

export interface ICreatedResponse {
  id: number;
}

export interface IInternalComment {
  id: number;
  from: INamedEntity;
  companyUnit: INamedEntity;
  comment: string;
  createdAt: Date;
  taggedUsers: INamedEntity[];
}

export type LawSettingsType = 'areas' | 'categories' | 'tags';

export interface ILawSettingsEntity extends INamedEntity {
  description?: string;
  connectedLaws: number;
  connectedCustomerRequirements?: number;
}

export interface INestedEntity extends INamedEntity {
  entities: INamedEntity[];
}

export interface ILawListFilters extends EntityList {
  areas: INamedEntity[];
  categories: INamedEntity[];
  companyUnits: INamedEntity[];
  complianceUsers: INestedEntity[];
  statuses: INamedEntity[];
  processes: INestedEntity[];
  aspects: INestedEntity[];
  tags: INestedEntity[];
}

export class AptorApi extends AuthenticatedApi {
  constructor(abortController: AbortController, locale: string) {
    super(abortController, getAppConfiguration().aptorApi, locale);
  }

  public invoke = async <T>(
    action: (api: AptorApi) => Promise<T>,
    abortController: AbortController,
    onValidationErrors: (errors: PropertyError[]) => void,
    onUnhandledErrors?: (errors: any) => void,
  ): Promise<T | undefined> => {
    try {
      return await action(this);
    } catch (error) {
      if (abortController.signal.aborted) {
        return;
      }
      if (error.response && error.response.status === 400) {
        const errorResponse = (await error.response.json()) as ErrorResponse;
        if (errorResponse.type === 'validation') {
          onValidationErrors(errorResponse.errors);
        } else if (onUnhandledErrors) {
          onUnhandledErrors(errorResponse);
        } else {
          throw errorResponse;
        }
        return undefined;
      } else {
        throw error;
      }
    }
  };

  public getUser(): Promise<IApiUser> {
    return this.get('/user');
  }

  public updateName<T>(data: FormValues): Promise<T> {
    return this.put(`/user/name`, data, true);
  }

  public async getLogotype(): Promise<string | undefined> {
    try {
      var result = await this.getBlobBase64('/logotype');
      return result;
    } catch (error) {
      return undefined;
    }
  }

  public getLanguages(): Promise<IListResult<ILanguage>> {
    return this.get('/languages');
  }

  public getMailSettings(): Promise<IListResult<IMailSettings>> {
    return this.get('/mail-settings');
  }

  public getCountries(): Promise<IListResult<ICountry>> {
    return this.get('/countries');
  }

  public updateSettings(data: FormValues): Promise<ICreatedResponse> {
    return this.put(`/settings`, data, true);
  }

  // ***** ADMIN MODULE ***** //

  public getImpersonationUsers(): Promise<IImpersonationUsers> {
    return this.get('/admin/impersonation-users');
  }

  // Admin/customers
  public searchCustomers<T>(request: IGridQueryRequest): Promise<IGridQueryResult<T>> {
    return this.post('/admin/customers/search', request);
  }

  public createCustomer(data: FormValues): Promise<void> {
    return this.post('/admin/customers/', data, true);
  }

  public newCustomer<T extends INamedEntity>(): Promise<IListResult<T>> {
    return this.get('/admin/customers/new');
  }

  public getCustomer(id: number): Promise<IApiCustomerInfo> {
    return this.get('/admin/customers/' + id);
  }

  public updateCustomer(id: number, data: FormValues): Promise<void> {
    return this.put('/admin/customers/' + id, data, true);
  }

  public reinviteCustomerUser(data: FormValues): Promise<void> {
    return this.post('/admin/resend-invite?email=' + data.email, undefined, true);
  }

  public toggleCustomerActivation(id: number, activate: boolean): Promise<void> {
    return this.put(`/admin/customers/${id}/activation`, { activate: activate }, true);
  }

  // Admin/users
  public inviteAdminUser(data: FormValues): Promise<void> {
    return this.post('/admin/users', data, true);
  }

  public updateAdminUser(id: number, data: FormValues): Promise<void> {
    return this.put(`/admin/users/${id}`, data, true);
  }

  public searchUsers<T>(request: IGridQueryRequest): Promise<IGridQueryResult<T>> {
    return this.post('/admin/users/search', request);
  }

  public toggleAptorUserActivation(id: number, activate: boolean): Promise<void> {
    return this.put(`/admin/users/${id}/status`, { activate: activate }, true);
  }

  public deleteAptorUser(id: number): Promise<void> {
    return this.delete(`/admin/users/${id}`, null, true);
  }

  public getAptorUser<T>(id: number): Promise<T> {
    return this.get(`/admin/users/${id}`);
  }

  public resendAptorUserInvite(id: number): Promise<void> {
    return this.post(`/admin/users/${id}/resend-invite`, {}, true);
  }

  // Admin/laws
  public getLawSettings(type: LawSettingsType | 'status'): Promise<IListResult<ILawSettingsEntity>> {
    return this.get(`/admin/laws/settings/${type}`);
  }

  public addLawSetting(type: LawSettingsType, item: { name: string; description?: string }): Promise<{ id: number }> {
    return this.post(`/admin/laws/settings/${type}`, item);
  }

  public editLawSetting(
    type: LawSettingsType,
    id: number,
    item: { name: string; description?: string },
  ): Promise<void> {
    return this.put(`/admin/laws/settings/${type}/${id}`, item, true);
  }

  public deleteLawSetting(type: LawSettingsType, id: number): Promise<void> {
    return this.delete(`/admin/laws/settings/${type}/${id}`, undefined, true);
  }

  public createLaw(data: FormValues): Promise<ICreatedResponse> {
    return this.post('/admin/laws', data);
  }

  public searchLaws<T>(request: IGridQueryRequest): Promise<IGridQueryResult<T>> {
    return this.post('/admin/laws/search', request);
  }

  public searchInvitations<T>(request: IGridQueryRequest): Promise<IGridQueryResult<T>> {
    return this.post('/admin/invitations/search', request);
  }

  public searchSendouts<T>(request: IGridQueryRequest): Promise<IGridQueryResult<T>> {
    return this.post('/admin/sendouts/search', request);
  }

  public getSendout<T>(id: number): Promise<T> {
    return this.get(`/admin/sendouts/${id}`);
  }

  public manualSendout(lawChangeId: number): Promise<void> {
    return this.post('/admin/sendouts?lawChangeId=' + lawChangeId, undefined, true);
  }

  public closeInvitation(id: string): Promise<void> {
    return this.put(`/admin/invitations/${id}/close`, undefined, true);
  }

  public getLaw<T>(id: number): Promise<T> {
    return this.get(`/admin/laws/${id}`);
  }

  public updateLawInformation(id: number, form: FormValues): Promise<void> {
    return this.put(`/admin/laws/${id}/information`, form, true);
  }

  public replaceLawDeprecated(id: number, replacedLawId: number | null): Promise<void> {
    return this.post(`/admin/laws/${id}/replace-deprecated`, { replacedLawId }, false);
  }
  public replaceLaw(id: number, replacedLawId: number | null): Promise<void> {
    return this.post(`/admin/laws/${id}/replace`, { replacedLawId }, true);
  }

  public deleteReplaceLaw(id: number, replacementId: number | null): Promise<void> {
    return this.post(`/admin/laws/${id}/replacements/${replacementId}`, undefined, true);
  }

  public searchAvailableLaws(params: {
    id?: number;
    unrelated?: boolean;
    search?: string;
  }): Promise<IListResult<INamedEntity>> {
    var idRouteSegment = params.id ? `/${params.id}/` : '/';
    return this.post(`/admin/laws${idRouteSegment}available/search`, {
      search: params.search,
      unrelated: !!params.unrelated,
    });
  }

  public searchRelatedLaws<T>(id: number, request: IGridQueryRequest): Promise<IGridQueryResult<T>> {
    return this.post(`/admin/laws/${id}/related/search`, request);
  }

  public addRelatedLaw(id: number, relatedLawId: number): Promise<void> {
    return this.post(`/admin/laws/${id}/related/${relatedLawId}`, undefined, true);
  }

  public removeRelatedLaw(id: number, relatedLawId: number): Promise<void> {
    return this.delete(`/admin/laws/${id}/related/${relatedLawId}`, undefined, true);
  }

  public updateLawShortDescription(id: number, value: string): Promise<void> {
    return this.put(`/admin/laws/${id}/short-description/`, { value }, true);
  }

  public updateLawChapter(id: number, value: string): Promise<void> {
    return this.put(`/admin/laws/${id}/chapter/`, { value }, true);
  }

  public updateLawApplication(id: number, value: string): Promise<void> {
    return this.put(`/admin/laws/${id}/application/`, { value }, true);
  }
  public addAndUpdateLawApplication(id: number,applicationID:number, value: any): Promise<void> {
    return this.post(`/admin/laws/${id}/applications/${applicationID}`,  value, true);
  }
  public addLawChange(id: number, form: FormValues): Promise<ICreatedResponse> {
    return this.post(`/admin/laws/${id}/changes`, form);
  }

  public updateLawChange(lawId: number, id: number, form: FormValues): Promise<void> {
    return this.put(`/admin/laws/${lawId}/changes/${id}`, form, true);
  }

  public deleteLawChange(lawId: number, id: number): Promise<void> {
    return this.delete(`/admin/laws/${lawId}/changes/${id}`, undefined, true);
  }

  public editLawChecklist<T>(
    id: number,
    checklist: T,
  ): Promise<{ groupsAdded: number; groupsRemoved: number; questionsAdded: number; questionsRemoved: number }> {
    return this.put(`/admin/laws/${id}/checklist`, checklist);
  }

  public getLawChecklist<T>(id: number): Promise<T> {
    return this.get(`/admin/laws/${id}/checklist`);
  }

  // Admin/audit
  public searchAudits<T>(request: IGridQueryRequest): Promise<IGridQueryResult<T>> {
    return this.post('/admin/audits/search', request);
  }

  public getAudit<T>(id: number): Promise<T> {
    return this.get(`/admin/audits/${id}`);
  }

  //ORGANIZATION MODULE
  public getCustomerLogotype(): Promise<{ logotype?: string }> {
    return this.get('/organization/logotype');
  }

  public uploadCustomerLogotype(file: File): Promise<void> {
    return this.upload(`/organization/customer/logotype`, file, true);
  }

  public deleteCustomerLogotype(): Promise<void> {
    return this.delete(`/organization/customer/logotype`, undefined, true);
  }

  // Organization/users
  public getCustomerUser<T>(id: number): Promise<T> {
    return this.get(`/organization/users/${id}`);
  }

  public getNewCustomerUser<T>(): Promise<T> {
    return this.get('/organization/users/new');
  }

  public searchCustomerUsers<T>(
    companyUnitId: number | undefined,
    request: IGridQueryRequest,
  ): Promise<IGridQueryResult<T>> {
    if (companyUnitId) {
      return this.post(`/organization/users/search?companyUnitId=${companyUnitId}`, request);
    }
    return this.post(`/organization/users/search`, request);
  }

  public inviteCustomerUser(companyUnitId: number | undefined, data: FormValues): Promise<void> {
    if (companyUnitId) {
      return this.post(`/organization/companyunits/${companyUnitId}/users/`, data, true);
    }
    return this.post('/organization/users/', data, true);
  }

  public resendCustomerUserInvite(id: number): Promise<void> {
    return this.post(`/organization/users/${id}/resend-invite`, {}, true);
  }

  public updateCustomerUser<T>(companyUnitId: number | undefined, id: number, data: FormValues): Promise<T> {
    if (companyUnitId) {
      return this.put(`/organization/companyunits/${companyUnitId}/users/${id}`, data, true);
    }
    return this.put(`/organization/users/${id}`, data, true);
  }

  public toggleCustomerUserActivation(id: number, activate: boolean): Promise<void> {
    return this.put(`/organization/users/${id}/status`, { activate: activate }, true);
  }

  public deleteCustomerUser(id: number): Promise<void> {
    return this.delete(`/organization/users/${id}`, null, true);
  }

  // Organization/companyunits
  public getOrganizationCompanyUnits<T extends INamedEntity>(): Promise<IListResult<T>> {
    return this.get('/organization/companyunits');
  }

  public getCompanyUnit<T extends INamedEntity>(id: number): Promise<T> {
    return this.get(`/organization/companyunits/${id}`);
  }

  public getCompanyUnitBalance<T>(): Promise<T> {
    return this.get('/organization/companyunits/balance');
  }

  public getUserBalance<T>(): Promise<T> {
    return this.get('/organization/users/balance');
  }

  public searchCompanyUnits<T>(request: IGridQueryRequest): Promise<IGridQueryResult<T>> {
    return this.post('/organization/companyunits/search', request);
  }

  public searchAzureTenants<T>(request: IGridQueryRequest): Promise<IGridQueryResult<T>> {
    return this.post('/organization/azure-tenants/search', request);
  }

  public createAzureTenant(data: FormValues): Promise<void> {
    return this.post('/organization/azure-tenants', data, true);
  }

  public updateAzureTenant(id: string, data: FormValues): Promise<void> {
    return this.put(`/organization/azure-tenants/${id}`, data, true);
  }

  public deleteAzureTenant(id: string): Promise<void> {
    return this.delete(`/organization/azure-tenants/${id}`, undefined, true);
  }

  public getAzureTenant<T>(id: string): Promise<T> {
    return this.get(`/organization/azure-tenants/${id}`);
  }

  public createCompanyUnit(data: FormValues): Promise<void> {
    return this.post('/organization/companyunits', data);
  }

  public updateCompanyUnit(id: number, data: FormValues): Promise<void> {
    return this.put(`/organization/companyunits/${id}`, data, true);
  }

  public toggleCompanyUnitActivation(id: number, activate: boolean): Promise<void> {
    return this.put(`/organization/companyunits/${id}/status`, { activate: activate }, true);
  }

  public updateCompanyUnitAlias(data: FormValues): Promise<void> {
    return this.put('/organization/companyunits/alias', data, true);
  }

  public uploadCompanyUnitLogotype(companyUnitId: number, file: File): Promise<void> {
    return this.upload(`/organization/companyunits/${companyUnitId}/logotype`, file, true);
  }

  public deleteCompanyUnitLogotype(companyUnitId: number): Promise<void> {
    return this.delete(`/organization/companyunits/${companyUnitId}/logotype`, undefined, true);
  }

  // Organization/companyunits/processes
  public getProcess<T extends INamedEntity>(companyUnitId: number, id: number): Promise<T> {
    return this.get(`/organization/companyunits/${companyUnitId}/processes/${id}`);
  }

  public searchProcesses<T>(companyUnitId: number, request: IGridQueryRequest): Promise<IGridQueryResult<T>> {
    return this.post(`/organization/companyunits/${companyUnitId}/processes/search`, request);
  }

  public createProcess(companyUnitId: number, data: FormValues): Promise<void> {
    return this.post(`/organization/companyunits/${companyUnitId}/processes`, data, true);
  }

  public updateProcess(companyUnitId: number, id: number, data: FormValues): Promise<void> {
    return this.put(`/organization/companyunits/${companyUnitId}/processes/${id}`, data, true);
  }

  public removeProcess(companyUnitId: number, id: number): Promise<void> {
    return this.delete(`/organization/companyunits/${companyUnitId}/processes/${id}`, undefined, true);
  }

  // Organization/companyunits/aspects
  public getAspect<T extends INamedEntity>(companyUnitId: number, id: number): Promise<T> {
    return this.get(`/organization/companyunits/${companyUnitId}/aspects/${id}`);
  }

  public searchAspects<T>(companyUnitId: number, request: IGridQueryRequest): Promise<IGridQueryResult<T>> {
    return this.post(`/organization/companyunits/${companyUnitId}/aspects/search`, request);
  }

  public createAspect(companyUnitId: number, data: FormValues): Promise<void> {
    return this.post(`/organization/companyunits/${companyUnitId}/aspects`, data, true);
  }

  public updateAspect(companyUnitId: number, id: number, data: FormValues): Promise<void> {
    return this.put(`/organization/companyunits/${companyUnitId}/aspects/${id}`, data, true);
  }

  public removeAspect(companyUnitId: number, id: number): Promise<void> {
    return this.delete(`/organization/companyunits/${companyUnitId}/aspects/${id}`, undefined, true);
  }

  // Organization/companyunits/tags
  public getTag<T extends INamedEntity>(companyUnitId: number, id: number): Promise<T> {
    return this.get(`/organization/companyunits/${companyUnitId}/tags/${id}`);
  }

  public searchTags<T>(companyUnitId: number, request: IGridQueryRequest): Promise<IGridQueryResult<T>> {
    return this.post(`/organization/companyunits/${companyUnitId}/tags/search`, request);
  }

  public createTag(companyUnitId: number, data: FormValues): Promise<void> {
    return this.post(`/organization/companyunits/${companyUnitId}/tags`, data, true);
  }

  public updateTag(companyUnitId: number, id: number, data: FormValues): Promise<void> {
    return this.put(`/organization/companyunits/${companyUnitId}/tags/${id}`, data, true);
  }

  public removeTag(companyUnitId: number, id: number): Promise<void> {
    return this.delete(`/organization/companyunits/${companyUnitId}/tags/${id}`, undefined, true);
  }

  //MANAGE LAW-PORTAL MODULE
  public getCustomerUsers<T extends INamedEntity>(): Promise<IListResult<T>> {
    return this.get('/law-portal/manage/users');
  }

  public getCustomerCompanyUnits<T extends INamedEntity>(): Promise<IListResult<T>> {
    return this.get('/law-portal/manage/companyunits');
  }

  public getCustomerCompanyUnitUsers<T extends INamedEntity>(id: number): Promise<IListResult<T>> {
    return this.get(`/law-portal/manage/companyunits/${id}/users`);
  }

  // Manage-law-portal/laws
  public getCustomerLawSettings(type: LawSettingsType | 'status'): Promise<IListResult<INamedEntity>> {
    return this.get(`/law-portal/manage/laws/settings/${type}`);
  }

  public getOrganizationLawFilters(): Promise<ILawListFilters> {
    return this.get('/law-portal/manage/laws/filters');
  }

  public getCustomerLaw<T extends INamedEntity>(id: number): Promise<T> {
    return this.get(`/law-portal/manage/laws/${id}`);
  }

  public searchCustomerLaws<T>(request: IGridQueryRequest): Promise<IGridQueryResult<T>> {
    return this.post('/law-portal/manage/laws/search', request);
  }

  public searchNoneCustomerLaws<T>(request: IGridQueryRequest): Promise<IGridQueryResult<T>> {
    return this.post('/law-portal/manage/laws/new/search', request);
  }

  public addCustomerLaws(data: FormValues): Promise<boolean> {
    return this.post(`/law-portal/manage/laws`, data);
  }

  public removeCustomerLaw(id: number): Promise<void> {
    return this.delete(`/law-portal/manage/laws/${id}`, undefined, true);
  }

  public getCustomerLawChecklist<T>(lawId: number, unitid: number): Promise<T> {
    return this.get(`/law-portal/manage/laws/${lawId}/companyunits/${unitid}/checklist`);
  }

  public updateCustomerLawChecklist<T>(lawId: number, unitid: number, checklist: T): Promise<void> {
    return this.put(`/law-portal/manage/laws/${lawId}/companyunits/${unitid}/checklist`, checklist, true);
  }

  // Manage-law-portal/laws/company-units
  public getLawCompanyUnit<T extends INamedEntity>(lawid: number, unitid: number): Promise<T> {
    return this.get(`/law-portal/manage/laws/${lawid}/companyunits/${unitid}`);
  }

  public getCustomerLawCompanyUnits<T extends INamedEntity>(lawid: number): Promise<IListResult<T>> {
    return this.get(`/law-portal/manage/laws/${lawid}/companyunits`);
  }

  public searchLawCompanyUnits<T>(lawid: number, request: IGridQueryRequest): Promise<IGridQueryResult<T>> {
    return this.post(`/law-portal/manage/laws/${lawid}/companyunits/search`, request);
  }

  public addLawCompanyUnits(lawid: number, data: FormValues): Promise<boolean> {
    return this.post(`/law-portal/manage/laws/${lawid}/companyunits/`, data);
  }

  public updateLawCompanyUnit(lawid: number, unitid: number, data: FormValues): Promise<void> {
    return this.put(`/law-portal/manage/laws/${lawid}/companyunits/${unitid}`, data, true);
  }

  public removeLawCompanyUnit(lawid: number, unitid: number): Promise<void> {
    return this.delete(`/law-portal/manage/laws/${lawid}/companyunits/${unitid}`, undefined, true);
  }

  public getCustomerCompanyUnitsLawConnections<T>(companyUnitId: number): Promise<T> {
    return this.get(`/law-portal/manage/companyunits/${companyUnitId}/law-connections/`);
  }

  public updateCustomerCompanyUnitsLawConnections(companyUnitId: number, type: "aspect" | 'process' | 'complianceUser' | 'tag', connection: number, addedToLaws: number[], removedFromLaws: number[]): Promise<void> {
    const data = { addedToLaws, removedFromLaws };
    return this.post(`/law-portal/manage/companyunits/${companyUnitId}/law-connections/${type}/${connection}`, data, true);
  }

  // Manage-law-portal/laws/company-units/processes
  public getCustomerLawProcesses<T extends INamedEntity>(lawid: number, unitid: number): Promise<IListResult<T>> {
    return this.get(`/law-portal/manage/laws/${lawid}/companyunits/${unitid}/processes`);
  }

  public searchLawProcesses<T>(
    lawid: number,
    unitid: number,
    request: IGridQueryRequest,
  ): Promise<IGridQueryResult<T>> {
    return this.post(`/law-portal/manage/laws/${lawid}/companyunits/${unitid}/processes/search`, request);
  }

  public addLawProcess(lawid: number, unitid: number, data: FormValues): Promise<void> {
    return this.post(`/law-portal/manage/laws/${lawid}/companyunits/${unitid}/processes`, data, true);
  }

  public removeLawProcess(lawid: number, unitid: number, processid: number): Promise<void> {
    return this.delete(
      `/law-portal/manage/laws/${lawid}/companyunits/${unitid}/processes/${processid}`,
      undefined,
      true,
    );
  }

  // Manage-law-portal/laws/company-units/aspects
  public getComanyUnitLawAspects<T extends INamedEntity>(lawid: number, unitid: number): Promise<IListResult<T>> {
    return this.get(`/law-portal/manage/laws/${lawid}/companyunits/${unitid}/aspects`);
  }

  public searchLawAspects<T>(lawid: number, unitid: number, request: IGridQueryRequest): Promise<IGridQueryResult<T>> {
    return this.post(`/law-portal/manage/laws/${lawid}/companyunits/${unitid}/aspects/search`, request);
  }

  public addLawAspect(lawid: number, unitid: number, data: FormValues): Promise<void> {
    return this.post(`/law-portal/manage/laws/${lawid}/companyunits/${unitid}/aspects/`, data, true);
  }

  public removeLawAspect(lawid: number, unitid: number, aspectid: number): Promise<void> {
    return this.delete(`/law-portal/manage/laws/${lawid}/companyunits/${unitid}/aspects/${aspectid}`, undefined, true);
  }

  // Manage-law-portal/laws/company-units/tags
  public getCustomerLawTags<T extends INamedEntity>(lawid: number, unitid: number): Promise<IListResult<T>> {
    return this.get(`/law-portal/manage/laws/${lawid}/companyunits/${unitid}/tags`);
  }

  public searchLawTags<T>(lawid: number, unitid: number, request: IGridQueryRequest): Promise<IGridQueryResult<T>> {
    return this.post(`/law-portal/manage/laws/${lawid}/companyunits/${unitid}/tags/search`, request);
  }

  public addLawTag(lawid: number, unitid: number, data: FormValues): Promise<void> {
    return this.post(`/law-portal/manage/laws/${lawid}/companyunits/${unitid}/tags/`, data, true);
  }

  public removeLawTag(lawid: number, unitid: number, tagid: number): Promise<void> {
    return this.delete(`/law-portal/manage/laws/${lawid}/companyunits/${unitid}/tags/${tagid}`, undefined, true);
  }

  // Manage-law-portal/requirements
  public getOrganizationRequirementFilters(): Promise<ILawListFilters> {
    return this.get('/law-portal/manage/requirements/filters');
  }

  public getRequirement<T extends INamedEntity>(id: number): Promise<T> {
    return this.get(`/law-portal/manage/requirements/${id}`);
  }

  public searchCustomerRequirements<T>(request: IGridQueryRequest): Promise<IGridQueryResult<T>> {
    return this.post('/law-portal/manage/requirements/search', request);
  }

  public createCustomerRequirement(data: FormValues): Promise<boolean> {
    return this.post('/law-portal/manage/requirements', data);
  }

  public editCustomerRequirement(id: number, data: FormValues): Promise<void> {
    return this.put(`/law-portal/manage/requirements/${id}`, data, true);
  }

  public removeCustomerRequirement(id: number): Promise<void> {
    return this.delete(`/law-portal/manage/requirements/${id}`, undefined, true);
  }

  public addRequirementChange(reqId: number, form: FormValues): Promise<ICreatedResponse> {
    return this.post(`/law-portal/manage/requirements/${reqId}/changes`, form);
  }

  public updateRequirementChange(reqId: number, changeId: number, form: FormValues): Promise<void> {
    return this.put(`/law-portal/manage/requirements/${reqId}/changes/${changeId}`, form, true);
  }

  public deleteRequirementChange(reqId: number, changeId: number): Promise<void> {
    return this.delete(`/law-portal/manage/requirements/${reqId}/changes/${changeId}`, undefined, true);
  }

  public getRequirementChecklist<T>(reqId: number, unitid: number): Promise<T> {
    return this.get(`/law-portal/manage/requirements/${reqId}/companyunits/${unitid}/checklist`);
  }

  public editRequirementChecklist<T>(
    reqId: number,
    unitid: number,
    checklist: T,
  ): Promise<{ groupsAdded: number; groupsRemoved: number; questionsAdded: number; questionsRemoved: number }> {
    return this.put(`/law-portal/manage/requirements/${reqId}/companyunits/${unitid}/checklist`, checklist);
  }

  // Manage-law-portal/requirements/company-units
  public getRequirementCompanyUnit<T extends INamedEntity>(reqid: number, unitid: number): Promise<T> {
    return this.get(`/law-portal/manage/requirements/${reqid}/companyunits/${unitid}`);
  }

  public getCustomerRequirementCompanyUnits<T extends INamedEntity>(reqid: number): Promise<IListResult<T>> {
    return this.get(`/law-portal/manage/requirements/${reqid}/companyunits`);
  }

  public searchRequirementCompanyUnits<T>(reqid: number, request: IGridQueryRequest): Promise<IGridQueryResult<T>> {
    return this.post(`/law-portal/manage/requirements/${reqid}/companyunits/search`, request);
  }

  public addRequirementCompanyUnits(reqid: number, data: FormValues): Promise<boolean> {
    return this.post(`/law-portal/manage/requirements/${reqid}/companyunits/`, data);
  }

  public updateRequirementCompanyUnit(reqid: number, unitid: number, data: FormValues): Promise<void> {
    return this.put(`/law-portal/manage/requirements/${reqid}/companyunits/${unitid}`, data, true);
  }

  public removeRequirementCompanyUnit(reqid: number, unitid: number): Promise<void> {
    return this.delete(`/law-portal/manage/requirements/${reqid}/companyunits/${unitid}`, undefined, true);
  }

  // Manage-law-portal/requirements/company-units/processes
  public getCustomerRequirementProcesses<T extends INamedEntity>(
    reqid: number,
    unitid: number,
  ): Promise<IListResult<T>> {
    return this.get(`/law-portal/manage/requirements/${reqid}/companyunits/${unitid}/processes`);
  }

  public searchRequirementProcesses<T>(
    reqid: number,
    unitid: number,
    request: IGridQueryRequest,
  ): Promise<IGridQueryResult<T>> {
    return this.post(`/law-portal/manage/requirements/${reqid}/companyunits/${unitid}/processes/search`, request);
  }

  public addRequirementProcess(reqid: number, unitid: number, data: FormValues): Promise<void> {
    return this.post(`/law-portal/manage/requirements/${reqid}/companyunits/${unitid}/processes`, data, true);
  }

  public removeRequirementProcess(reqid: number, unitid: number, processid: number): Promise<void> {
    return this.delete(
      `/law-portal/manage/requirements/${reqid}/companyunits/${unitid}/processes/${processid}`,
      undefined,
      true,
    );
  }

  // Manage-law-portal/requirements/company-units/aspects
  public getCustomerRequirementAspects<T extends INamedEntity>(reqid: number, unitid: number): Promise<IListResult<T>> {
    return this.get(`/law-portal/manage/requirements/${reqid}/companyunits/${unitid}/aspects`);
  }

  public searchRequirementAspects<T>(
    reqid: number,
    unitid: number,
    request: IGridQueryRequest,
  ): Promise<IGridQueryResult<T>> {
    return this.post(`/law-portal/manage/requirements/${reqid}/companyunits/${unitid}/aspects/search`, request);
  }

  public addRequirementAspect(reqid: number, unitid: number, data: FormValues): Promise<void> {
    return this.post(`/law-portal/manage/requirements/${reqid}/companyunits/${unitid}/aspects/`, data, true);
  }

  public removeRequirementAspect(reqid: number, unitid: number, aspectid: number): Promise<void> {
    return this.delete(
      `/law-portal/manage/requirements/${reqid}/companyunits/${unitid}/aspects/${aspectid}`,
      undefined,
      true,
    );
  }

  // Manage-law-portal/requirements/company-units/tags
  public getCustomerRequirementTags<T extends INamedEntity>(reqid: number, unitid: number): Promise<IListResult<T>> {
    return this.get(`/law-portal/manage/requirements/${reqid}/companyunits/${unitid}/tags`);
  }

  public searchRequirementTags<T>(
    reqid: number,
    unitid: number,
    request: IGridQueryRequest,
  ): Promise<IGridQueryResult<T>> {
    return this.post(`/law-portal/manage/requirements/${reqid}/companyunits/${unitid}/tags/search`, request);
  }

  public addRequirementTag(reqid: number, unitid: number, data: FormValues): Promise<void> {
    return this.post(`/law-portal/manage/requirements/${reqid}/companyunits/${unitid}/tags/`, data, true);
  }

  public removeRequirementTag(reqid: number, unitid: number, tagid: number): Promise<void> {
    return this.delete(
      `/law-portal/manage/requirements/${reqid}/companyunits/${unitid}/tags/${tagid}`,
      undefined,
      true,
    );
  }

  //LAW-PORTAL MODULE

  public getDashboardChanges<T>(): Promise<T> {
    return this.get('/law-portal/dashboard/changes');
  }

  public getDashboardTaggedItems<T>(): Promise<IListResult<T>> {
    return this.get('/law-portal/dashboard/tagged');
  }

  public getDashboardNewUserItems<T>(): Promise<IListResult<T>> {
    return this.get('/law-portal/dashboard/new-users');
  }

  public markDashboardLawTagsAsRead(id: number): Promise<void> {
    return this.post(`/law-portal/dashboard/tagged/laws/${id}/mark-as-read`, undefined, true);
  }

  public markDashboardRequirementTagsAsRead(id: number): Promise<void> {
    return this.post(`/law-portal/dashboard/tagged/requirements/${id}/mark-as-read`, undefined, true);
  }

  public markDashboardNewUserAsHandled(id: number): Promise<void> {
    return this.post(`/law-portal/dashboard/new-users/${id}/mark-as-handled`, undefined, true);
  }

  public supportInvite(data: FormValues): Promise<void> {
    return this.post('/law-portal/support-invitation', data, true);
  }

  // LawPortal/laws
  public getLawPortalLawFilters(): Promise<ILawListFilters> {
    return this.get('/law-portal/laws/filters');
  }

  public searchLawPortalLaws<T>(request: IGridQueryRequest): Promise<IGridQueryResult<T>> {
    return this.post('/law-portal/laws/search', request);
  }
  public ExportLawPortalLaws() {
    return this.gets('/law-portal/laws/export');
  }
  public getLawPortalLawInfo<T>(lawId: number): Promise<T> {
    return this.get(`/law-portal/laws/${lawId}/info`);
  }

  public searchLawPortalRelatedLaws<T>(lawId: number, request: IGridQueryRequest): Promise<IGridQueryResult<T>> {
    return this.post(`/law-portal/laws/${lawId}/related`, request);
  }

  public getLawPortalLawComments(lawId: number): Promise<IListResult<IInternalComment>> {
    return this.get(`/law-portal/laws/${lawId}/comments`);
  }

  public getLawPortalLawCommentCompanyUnits<T>(lawId: number): Promise<IListResult<T>> {
    return this.get(`/law-portal/laws/${lawId}/comments/new`);
  }

  public addLawPortalLawComment(
    lawId: number,
    customerCompanyUnitId: number,
    comment: string,
    taggedUserIds: number[],
  ): Promise<ICreatedResponse> {
    return this.post(`/law-portal/laws/${lawId}/comments`, {
      comment,
      customerCompanyUnitId,
      taggedUserIds,
    });
  }

  public editLawPortalLawComment(lawId: number, comment: IInternalComment): Promise<void> {
    return this.put(
      `/law-portal/laws/${lawId}/comments/${comment.id}`,
      {
        comment: comment.comment,
        customerCompanyUnitId: comment.companyUnit.id,
        taggedUserIds: comment.taggedUsers.map((x) => x.id),
      },
      true,
    );
  }

  public deleteLawPortalLawComment(lawId: number, commentId: number): Promise<void> {
    return this.delete(`/law-portal/laws/${lawId}/comments/${commentId}`, undefined, true);
  }

  // LawPortal/laws/overview
  public getLawPortalLawOverview<T>(lawId: number): Promise<T> {
    return this.get(`/law-portal/laws/${lawId}/overview`);
  }

  public searchLawComplianceIntervals<T>(lawId: number, request: IGridQueryRequest): Promise<IGridQueryResult<T>> {
    return this.post(`/law-portal/laws/${lawId}/compliance-intervals`, request);
  }

  public searchLawComplianceUsers<T>(lawId: number, request: IGridQueryRequest): Promise<IGridQueryResult<T>> {
    return this.post(`/law-portal/laws/${lawId}/compliance-users`, request);
  }

  public getLawComplianceInterval<T>(lawId: number, companyUnitId: number): Promise<{ start: string; end: string }> {
    return this.get(`/law-portal/laws/${lawId}/companyunits/${companyUnitId}/compliance-interval`);
  }

  public getRequirementComplianceInterval<T>(
    requirementId: number,
    companyUnitId: number,
  ): Promise<{ start: string; end: string }> {
    return this.get(`/law-portal/requirements/${requirementId}/companyunits/${companyUnitId}/compliance-interval`);
  }

  public setLawComplianceInterval(lawId: number, unitId: number, payload: FormValues): Promise<void> {
    return this.put(`/law-portal/laws/${lawId}/companyunits/${unitId}/compliance-interval`, payload, true);
  }

  public deleteLawComplianceInterval(lawId: number, unitId: number): Promise<void> {
    return this.delete(`/law-portal/laws/${lawId}/companyunits/${unitId}/compliance-interval`, undefined, true);
  }

  public updateLawPortalLawApplicationHidden(
    lawId: number,
    applicationId:number,
    companyUnits: Array<{ id: number; applicationHidden: boolean }>,
  ): Promise<void> {
    return this.put(`/law-portal/laws/${lawId}/applications/${applicationId}`, { companyUnits }, true);
  }

  public updateLawPortalLawNote(lawId: number, companyUnitId: number, note: string | null): Promise<void> {
    return this.put(`/law-portal/laws/${lawId}/companyunits/${companyUnitId}/note`, { note }, true);
  }

  // LawPortal/laws/changes
  public getLawPortalLawChanges<T>(lawId: number): Promise<IListResult<T>> {
    return this.get(`/law-portal/laws/${lawId}/changes`);
  }

  public searchUnacknowledgedLawChanges<T>(lawId: number, request: IGridQueryRequest): Promise<IGridQueryResult<T>> {
    return this.post(`/law-portal/laws/${lawId}/changes/unacknowledged`, request);
  }

  public acknowledgeLawPortalLawChange(lawId: number, lawChangeId: number, comment?: string,affect?:boolean): Promise<void> {
    return this.post(`/law-portal/laws/${lawId}/change/${lawChangeId}/acknowledge`, { comment: comment ,AffectsBusiness:affect}, true);
  }

  // LawPortal/laws/compliance-control
  public getLawPortalLawChecklist<T>(lawId: number, unitId: number): Promise<T> {
    return this.get(`/law-portal/laws/${lawId}/companyunits/${unitId}/checklist`);
  }
  public getLawPortalLawChecklistWithData<T>(planId:number,lawId: number): Promise<T> {
    return this.get(`/law-portal/plan/${planId}/laws/${lawId}/checklist-with-answers`);
  }

  public getUserCompanyUnitsForLawCompliance(lawId: number): Promise<IListResult<INamedEntity>> {
    return this.get(`/law-portal/laws/${lawId}/user-company-units`);
  }

  public getParticipantsForLawCompliance(lawId: number, companyUnitId: number): Promise<IListResult<INamedEntity>> {
    return this.get(`/law-portal/laws/${lawId}/companyunits/${companyUnitId}/compliance-users`);
  }

  public createLawPortalLawComplianceReport<T>(lawId: number, companyUnitId: number, data: T) {
    return this.post(`/law-portal/laws/${lawId}/companyunits/${companyUnitId}/compliance-report`, data);
  }

  public searchLawComplianceReports<T>(lawId: number, request: IGridQueryRequest): Promise<IGridQueryResult<T>> {
    return this.post(`/law-portal/laws/${lawId}/compliance-reports/search`, request);
  }

  public downloadLawComplianceReport(lawId: number, reportId: number) {
    return this.getDownload(`/law-portal/laws/${lawId}/compliance-reports/${reportId}`);
  }

  // LawPortal/requirements
  public getLawPortalRequirementFilters(): Promise<ILawListFilters> {
    return this.get('/law-portal/requirements/filters');
  }

  public searchLawPortalRequirements<T>(request: IGridQueryRequest): Promise<IGridQueryResult<T>> {
    return this.post('/law-portal/requirements/search', request);
  }
  public exportLawPortalRequirements(){
    return this.gets('/law-portal/requirements/export');
  }

  public getLawPortalRequirementInfo<T>(reqId: number): Promise<T> {
    return this.get(`/law-portal/requirements/${reqId}/info`);
  }

  public getLawPortalRequirementComments(reqId: number): Promise<IListResult<IInternalComment>> {
    return this.get(`/law-portal/requirements/${reqId}/comments`);
  }

  public getLawPortalRequirementCommentCompanyUnits<T>(requirementId: number): Promise<IListResult<T>> {
    return this.get(`/law-portal/requirements/${requirementId}/comments/new`);
  }

  public addLawPortalRequirementComment(
    reqId: number,
    customerCompanyUnitId: number,
    comment: string,
    taggedUserIds: number[],
  ): Promise<ICreatedResponse> {
    return this.post(`/law-portal/requirements/${reqId}/comments`, {
      comment,
      customerCompanyUnitId,
      taggedUserIds,
    });
  }

  public editLawPortalRequirementComment(reqId: number, comment: IInternalComment): Promise<void> {
    return this.put(
      `/law-portal/requirements/${reqId}/comments/${comment.id}`,
      {
        comment: comment.comment,
        customerCompanyUnitId: comment.companyUnit.id,
        taggedUserIds: comment.taggedUsers.map((x) => x.id),
      },
      true,
    );
  }

  public deleteLawPortalRequirementComment(reqId: number, commentId: number): Promise<void> {
    return this.delete(`/law-portal/requirements/${reqId}/comments/${commentId}`, undefined, true);
  }

  // LawPortal/requirements/overview
  public getLawPortalRequirementOverview<T>(reqId: number): Promise<T> {
    return this.get(`/law-portal/requirements/${reqId}/overview`);
  }

  public searchRequirementComplianceUsers<T>(reqId: number, request: IGridQueryRequest): Promise<IGridQueryResult<T>> {
    return this.post(`/law-portal/requirements/${reqId}/compliance-users`, request);
  }

  public searchRequirementComplianceIntervals<T>(
    reqId: number,
    request: IGridQueryRequest,
  ): Promise<IGridQueryResult<T>> {
    return this.post(`/law-portal/requirements/${reqId}/compliance-intervals`, request);
  }

  public setRequirementComplianceInterval(reqId: number, unitId: number, payload: FormValues): Promise<void> {
    return this.put(`/law-portal/requirements/${reqId}/companyunits/${unitId}/compliance-interval`, payload, true);
  }

  public deleteRequirementComplianceInterval(reqId: number, unitId: number): Promise<void> {
    return this.delete(`/law-portal/requirements/${reqId}/companyunits/${unitId}/compliance-interval`, undefined, true);
  }

  public updateLawPortalRequirementNote(reqId: number, companyUnitId: number, note: string | null): Promise<void> {
    return this.put(`/law-portal/requirements/${reqId}/companyunits/${companyUnitId}/note`, { note }, true);
  }

  // LawPortal/requirements/changes
  public getLawPortalRequirementChanges<T>(reqId: number): Promise<IListResult<T>> {
    return this.get(`/law-portal/requirements/${reqId}/changes`);
  }

  public searchUnacknowledgedRequirementChanges<T>(
    reqId: number,
    request: IGridQueryRequest,
  ): Promise<IGridQueryResult<T>> {
    return this.post(`/law-portal/requirements/${reqId}/changes/unacknowledged`, request);
  }

  public acknowledgeLawPortalRequirementChange(reqId: number, lawChangeId: number, comment?: string,affect?:boolean): Promise<void> {
    return this.post(
      `/law-portal/requirements/${reqId}/changes/${lawChangeId}/acknowledge`,
      { comment: comment,AffectsBusiness:affect },
      true,
    );
  }

  // LawPortal/requirements/compliance-control
  public getLawPortalRequirementChecklist<T>(unitId: number, reqId: number): Promise<T> {
    return this.get(`/law-portal/requirements/${reqId}/companyunits/${unitId}/checklist`);
  }

  public getUserCompanyUnitsForRequirementCompliance(reqId: number): Promise<IListResult<INamedEntity>> {
    return this.get(`/law-portal/requirements/${reqId}/user-company-units`);
  }

  public getParticipantsForRequirementCompliance(
    reqId: number,
    companyUnitId: number,
  ): Promise<IListResult<INamedEntity>> {
    return this.get(`/law-portal/requirements/${reqId}/companyunits/${companyUnitId}/compliance-users`);
  }

  public createLawPortalRequirementComplianceReport<T>(reqId: number, unitId: number, data: T) {
    return this.post(`/law-portal/requirements/${reqId}/companyunits/${unitId}/compliance-reports`, data);
  }

  public searchRequirementComplianceReports<T>(
    reqId: number,
    request: IGridQueryRequest,
  ): Promise<IGridQueryResult<T>> {
    return this.post(`/law-portal/requirements/${reqId}/compliance-reports/search`, request);
  }

  public downloadRequirementComplianceReport(reqId: number, reportId: number) {
    return this.getDownload(`/law-portal/requirements/${reqId}/compliance-reports/${reportId}`);
  }

  // LawPortal/Statistics
  public getStatisticsFilters(requirements: boolean = false): Promise<ILawListFilters> {
    return this.get('/law-portal/statistics/filters', { requirements });
  }

  public searchStatisticsUnacknowledgedChanges<T>(request: IGridQueryRequest): Promise<IGridQueryResult<T>> {
    return this.post(`/law-portal/statistics/changes/unacknowledged/search`, request);
  }

  public searchStatisticsAcknowledgedChanges<T>(request: IGridQueryRequest): Promise<IGridQueryResult<T>> {
    return this.post(`/law-portal/statistics/changes/acknowledged/search`, request);
  }

  public searchStatisticsCompliancesDue<T>(request: IGridQueryRequest): Promise<IGridQueryResult<T>> {
    return this.post(`/law-portal/statistics/compliance-reports/due/search`, request);
  }

  public searchStatisticsRequirementCompliancesDue<T>(request: IGridQueryRequest): Promise<IGridQueryResult<T>> {
    return this.post(`/law-portal/statistics/requirement-compliance-reports/due/search`, request);
  }

  public searchStatisticsCompliances<T>(request: IGridQueryRequest): Promise<IGridQueryResult<T>> {
    return this.post(`/law-portal/statistics/compliance-reports/search`, request);
  }

  public searchStatisticsRequirementCompliances<T>(request: IGridQueryRequest): Promise<IGridQueryResult<T>> {
    return this.post(`/law-portal/statistics/requirement-compliance-reports/search`, request);
  }
  public createCompliancePlan<T>(customerId: number|null, data: T) {
    return this.post(`/admin/customers/${customerId}/plan`, data);
  }
  public getAllcompliancePlan<T>(customerId: number|null,data:T) {
    return this.post(`/admin/customers/${customerId}/plans`,data);
  }

  public getAllcompliancePlanAllControl<T>(customerId:any,data: T): Promise<IGridQueryResult<T>> {
    return this.post(`/admin/customers/${customerId}/plans-with-legislations`,data);
  }
  public getPlanLaws<T>(planId: number|null) {
    return this.get(`/admin/plans/${planId}/laws`);
  }
  public getPlanAllLawID<T>(planId: number|null) {
    return this.get(`/admin/plans/${planId}/legislations-ids`);
  }
  public addLaws<T>(planId: number|null,data:T) {
    return this.post(`/admin/plans/${planId}/legislations`,data);
  }
  public searchPlanLaws<T>(planId:number|null,request: IGridQueryRequest): Promise<IGridQueryResult<T>> {
    return this.post(`/admin/plans/${planId}/laws/search`, request);
  }
  public updatePlanLaws<T>(planId:number|null, data: T) {
    return this.put(`/admin/plans/${planId}`, data,true);
  }
  public planStatus<T>(planId:number|null){
    return this.post(`/admin/plan/${planId}/toggle-active-status`);
  }
  public deletePlan<T>(planId:number|null){
    return this.delete(`/admin/plan/${planId}`);
  }
  public searchdirectPlanLaws<T>(planId:number|null) {
    return this.get(`/admin/plans/${planId}/laws/`);
  }
  public getdirectPlanLaws<T>(planId:number|null) {
    return this.get(`/admin/plans/${planId}/complied-legislations/`);
  } 
  public searchLawPortalRequirementLaws<T>(request: IGridQueryRequest): Promise<IGridQueryResult<T>> {
    return this.post('/law-portal/requirements/search', request);
  }
  public createplanReport<T>(planId: number|null,reportId:number|null, data:number) {
    return this.post(`/law-portal/plan/${planId}/create-report-map/${reportId}`, data);
  }
  public updateLawPortalLawComplianceReport<T>(lawId: number, unitId: number,reportId:number, data: T) {
    return this.post(`/law-portal/laws/${lawId}/companyunits/${unitId}/compliance-report/${reportId}`, data);
  }
  public getReportPlan<T>(data:T){
    return this.post("/customers/plans/reports",data)
  }
  public getAllControlReport<T>(customerId:number,data:T): Promise<T> {
    return this.post(`/customers/${customerId}/executed-legislations`,data);
  }
  public searchPlanReports<T>(planId:number,request: any) {
    return this.post(`/customers/plan/${planId}/executed-legislations/search`, request);
  }
  public searchPlanRequirementLaws<T>(planId: number|null,request: any) {
    return this.post(`/customer/plan/${planId}/requirements/search`, request);
  }
  public getPlanRequirement<T>(planId:number|null) {
    return this.get(`/customer/plan/${planId}/requirements/`);
  }
  public getLawPortalRequirementChecklistWithData<T>(planId:number,requirementId: number): Promise<T> {
    return this.get(`/law-portal/plan/${planId}/requirements/${requirementId}/checklist-with-answers`);
  }
  public updateLawPortalRequirementComplianceReport<T>(requirementId: number, unitId: number,reportId:number, data: T) {
    return this.post(`/law-portal/requirements/${requirementId}/companyunits/${unitId}/compliance-report/${reportId}`, data);
  }

  public searchLawPortalPlanRequirementLaws<T>(planId:any,request: IGridQueryRequest): Promise<IGridQueryResult<T>> {
    return this.post(`/admin/plans/${planId}/complied-legislations/requirements/search`, request);
  }
  public searchLawPortalPlanLaws<T>(planId:any,request: IGridQueryRequest): Promise<IGridQueryResult<T>> {
    return this.post(`/admin/plans/${planId}/complied-legislations/laws/search`, request);
  }
  public searchlawUnacknowledgedChanges<T>(request: IGridQueryRequest): Promise<IGridQueryResult<T>> {
    return this.post(`/law-portal/laws/changes/unacknowledged/search`, request);
  }
  public exportlawUnacknowledgedChanges(){
    return this.gets(`/law-portal/laws/changes/unacknowledged/export`);
  }
  public searchlawAcknowledgedChanges<T>(request: IGridQueryRequest): Promise<IGridQueryResult<T>> {
    return this.post(`/law-portal/laws/changes/acknowledged/search`, request);
  }
  public exportlawAcknowledgedChanges() {
    return this.gets(`/law-portal/laws/changes/acknowledged/export`);
  }
  public searchrequirementsUnacknowledgedChanges<T>(request: IGridQueryRequest): Promise<IGridQueryResult<T>> {
    return this.post(`/law-portal/requirements/changes/unacknowledged/search`, request);
  }
  public exportrequirementsUnacknowledgedChanges() {
    return this.gets(`/law-portal/requirements/changes/unacknowledged/export`);
  }
  public searchrequirementsAcknowledgedChanges<T>(request: IGridQueryRequest): Promise<IGridQueryResult<T>> {
    return this.post(`/law-portal/requirements/changes/acknowledged/search`, request);
  }
  public exportrequirementsAcknowledgedChanges() {
    return this.gets(`/law-portal/requirements/changes/acknowledged/export`);
  }
  public getSaveColumn<T>(userId:any,tableValue:any) {
    return this.get(`/law-portal/feature/manage/${userId}/table/${tableValue}/column-settings`);
  }
  public saveColumn<T>(request: any){
    return this.post(`/law-portal/feature/manage/column-settings`, request);
  }
  public getPlanLawsexecute<T>(planId: number|null) {
    return this.get(`/admin/plans/${planId}/laws-to-execute`);
  }
  public getPlanrequirementexecute<T>(planId: number|null) {
    return this.get(`/admin/plans/${planId}/requirements-to-execute`);
  }
}
