import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { Form } from '..';
import { AptorApi } from '../../Api';
import { FormValues } from '../Form/Form.types';
import { useFormField } from '../Form/FormField';

interface IProps {
  value: string;
  propertyNameTranslationKey: string;
  headingValue?: string;
  submit: (
    value: string,
    api: AptorApi,
    onSuccess: (successMessage?: string | undefined | null) => void,
  ) => Promise<void>;
  heading?: boolean;
}

export const HtmlForm: FC<IProps> = ({ value, submit, propertyNameTranslationKey, heading, headingValue }) => {
  const { formatMessage } = useIntl();
  const onSubmit = (form: FormValues, api: AptorApi, onSuccess: (successMessage?: string | undefined | null) => void) =>
    submit(form['value'] as string, api, onSuccess);
  const field = useFormField({
    label: formatMessage({ id: 'form.update.field' }, { field: formatMessage({ id: propertyNameTranslationKey }) }),
    name: 'value',
    type: 'rich-text',
    required: true,
    initialState: { value },
  });
  const application = useFormField({
    label: formatMessage({ id: 'form.update.field' }, { field: formatMessage({ id: propertyNameTranslationKey }) }),
    name: 'application',
    type: 'rich-text',
    required: true,
    initialState: { value },
  });
  const headingfield = useFormField({
    label: formatMessage({ id: 'form.field.heading' }),
    name: 'heading',
    type: 'text',
    required: true,
    initialState: headingValue ? { value: headingValue } : undefined,
  });


  return heading ? <Form submit={(form: any, api: any, onSuccess: any) => submit(form, api, onSuccess)} groups={[{ grid: { type: 'column', items: [headingfield, application] } }]} /> : <Form submit={onSubmit} groups={[{ grid: { type: 'row', items: [field] } }]} />;
};
